import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import ScrollAnimation from './directives/scrollanimation'
import '@mdi/font/css/materialdesignicons.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.directive('scrollanimation', ScrollAnimation);

Vue.config.productionTip = false

new Vue({
  created () { 
    AOS.init () 
  }, 
  icons: {
    iconfont: 'mdi'
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
