<template>
    <div class="whatsapp">
        <v-menu transition="slide-y-reverse-transition" style="top:275px!important" open-on-hover  top :close-on-content-click="false" internal-activator  offset-y>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    fixed
                    dark
                    bottom
                    left
                    color="#25d366"
                    v-on="on"
                    style="animation: fadeInAndExpand--10dMR 288ms cubic-bezier(0.4, 0, 0.2, 1) !important;"
                >
                    <img src="@/assets/whatsapp_white.svg" width="28" aspect-ratio="1.4"/>
                </v-btn>
            </template>
            <v-list dense>
                <div v-for="(item, index) in whatsapp" :key="index">
                    <v-list-item-group>
                        <v-list-item :href="item.link" target="_blank">
                            <v-list-item-icon class="mr-4">
                                <img width="25" :src="item.bandera">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.pais"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </div>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
export default {
    name: 'Whatsapp',
    data() {
        return {
            whatsapp:[
                {pais:'Guatemala',bandera:require('@/assets/banderas/guatemala.webp'),link:'https://api.whatsapp.com/send?phone=50224273480&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20soluciones.'},
                {pais:'El Salvador',bandera:require('@/assets/banderas/el_salvador.png'),link:'https://api.whatsapp.com/send?phone=50378415600&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20soluciones'},
                {pais:'Honduras',bandera:require('@/assets/banderas/honduras.png'),link:'https://api.whatsapp.com/send?phone=50242654423&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20soluciones.'},
                {pais:'Nicaragua',bandera:require('@/assets/banderas/nicaragua.png'),link:'https://api.whatsapp.com/send?phone=50242654423&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20soluciones.'},
                {pais:'Costa Rica',bandera:require('@/assets/banderas/costa_rica.png'),link:'https://api.whatsapp.com/send?phone=50242654423&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20soluciones.'},
                {pais:'Panamá',bandera:require('@/assets/banderas/panama.png'),link:'https://api.whatsapp.com/send?phone=50769498712&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20soluciones.'},
                {pais:'Bolivia',bandera:require('@/assets/banderas/bolivia.png'),link:'https://api.whatsapp.com/send?phone=59176050080&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20soluciones.'}
            ]
        }
    },
}
</script>
<style>
    .v-list--dense .v-list-item .v-list-item__icon{
        height: auto!important;
        padding-top: 3px;
    }
    .v-btn--fixed.v-btn--bottom {
        bottom: 98px;
    }
</style>