<template>
  <v-app>
    <Navbar/>
    <v-sheet>
      <v-content>
        <router-view></router-view>
      </v-content>
    </v-sheet>
    <Whatsapp/>
    <Footer/>
  </v-app>
</template>

<script>
import Navbar from '@/components/navbar';
import Whatsapp from '@/components/whatsapp';
import Footer from '@/components/footer';
export default {
  name: 'App',

  components: {
    Navbar,
    Whatsapp,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>
