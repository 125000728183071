<template>
  <v-app-bar  flat class="header_area header_stick" height="94">
    <v-toolbar color="transparent" flat dark height="94">
      <v-container class=" d-flex">
        <router-link to="/">
          <img
            src="@/assets/LogoDisatel.svg"
            alt="Corporación Disatel"
            width="110"
            aspect-ratio="1"
            class="logo"
          />
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-items v-for="(item, index) in opciones" :key="index" class="align-center">
          <v-btn
            class="btn-mod raleway-medium  text-capitalize hidden-sm-and-down"
            text
            v-text="item.titulo"
            :to="item.link"
            :href="item.enlace ? item.enlace : ''"
            style="min-width:48px"
            
          >
          </v-btn>
          
        </v-toolbar-items>
        <v-toolbar-items class="align-center">
          <v-btn
            class="raleway-bold  text-capitalize"
            text
            href="https://marchamoelectronicogt.com"
            style="min-width:48px;"
            v-text="'Marchamo Electrónico'"
            :width="$vuetify.breakpoint.mdAndDown ? '100' : ''"
            :style="$vuetify.breakpoint.mdAndDown ? 'white-space: normal!important;' : ''"
          >
            
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-menu transition="slide-y-transition" open-on-hover left :close-on-content-click="false" internal-activator attach offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                class="raleway-medium  text-capitalize "
                v-on="on"
                style="border-radius: 5px!important;"
                v-text="$vuetify.breakpoint.mdAndDown ? 'Usuarios' : 'Acceso a Usuarios' "
              >
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item href="https://marchamoelectronicogt.com" target="_blank">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-black red--text text--darken-4">Marchamo Electrónico</v-list-item-title> 
                </v-list-item-content>
                <div class="float-right">
                  <v-chip
                    x-small
                    class="ml-2"
                    color="success"
                  >
                    Nuevo
                  </v-chip>
                </div>
              </v-list-item>
              <div v-for="(item, index) in acceso" :key="index">
                <v-list-group color="#424242" v-show="item.tipo == 1">
                  <template v-slot:activator>
                    <v-list-item-content >
                      <v-list-item-title style="padding;" class="font-weight-black red--text text--darken-4" v-text="item.titulo"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item :href="item.link" class=" padding font-italic" target="_blank" v-for="(item, index) in item.subacceso" :key="index">
                    <v-list-item-content>
                      <v-list-item-title style="padding-left: 15px;" v-text="item.titulo" class="gray-3-text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

              </div>
            </v-list>
          </v-menu>
        </v-toolbar-items>
        <v-btn
          class="mx-2 hidden-md-and-up"
          text
          fab
          color="white"
          active-class="red"
          @click="drawer = !drawer"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      class="red darken-4"
      dark
      temporary
      absolute
      floating
      style="height: 100vh; width:75%; z-index:2000000001"
    >
      <div class="align-center" style="height: 100vh;">
        <v-list rounded width="100%">
          <v-list-item
            v-for="(item, index) in opciones"
            :key="index"
            link
            :to="item.link"
            :href="item.enlace ? item.enlace : ''"
            
            class="py-2 mb-12 overline"
          >
            <v-list-item-content class="text-center">
              <v-list-item-title>{{ item.titulo }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <img  aspect-ratio="1" v-if="item.img" :src="item.img ? item.img : ''"  height="25" alt=""/>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </v-app-bar>
</template>
<style>
.v-menu__content {
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.05) !important;
}
</style>
<script>
import * as navbarConfig from "../../public/js/navbar";
import $ from "jquery";
$(window).scroll(navbarConfig.menu_estatico_1);
export default {
  name: "Navbar",
  components: {},
  data() {
    return {
      offsetTop: 0,
      opciones: [
        { titulo: "Inicio", link: "/" },
        { titulo: "Nosotros", link: "/nosotros" },
        { titulo: "Servicios", link: "/servicios" },
        { titulo: "Contáctanos", link: "/contactanos" },
        { titulo: "Logical Commander", link: "/logical-commander"}
        //{ titulo: "Marchamo Electrónico Guatemala", enlace: "https://cloud.kreabit.com/webmarchamo", img: require('@/assets/logo_sat.png')}
      ],
      acceso: [
        {
          tipo: "1",
          titulo: "Guatemala",
          subacceso: [
            { titulo: "AVL (v8.5)", link: "https://gt.disatelgps.com/" },
            { titulo: "Traffilog", link: "https://html5.traffilog.com/appv2/index.htm" },
            { titulo: "Satlock", link: "http://www.satlockonline.com/" }
          ]
        },
        {
          tipo: "1",
          titulo: "El Salvador",
          subacceso: [
            { titulo: "AVL (v8.5)", link: "http://sv.disatelgps.com/" },
            { titulo: "Traffilog", link: "https://html5.traffilog.com/appv2/index.htm" },
            { titulo: "Satlock", link: "http://www.satlockonline.com/" }
          ]
        },
        {
          tipo: "1",
          titulo: "Honduras",
          subacceso: [
            { titulo: "AVL (v8.5)", link: "https://gt.disatelgps.com/" },
            { titulo: "Traffilog", link: "https://html5.traffilog.com/appv2/index.htm" },
            { titulo: "Satlock", link: "http://www.satlockonline.com/" }
          ]
        },
        {
          tipo: "1",
          titulo: "Nicaragua",
          link: "",
          subacceso: [
            { titulo: "AVL (v8.5)", link: "https://ni.disatelgps.com/" },
            { titulo: "Traffilog", link: "https://html5.traffilog.com/appv2/index.htm" },
            { titulo: "Satlock", link: "http://www.satlockonline.com/" }
          ]
        },
        {
          tipo: "1",
          titulo: "Costa Rica",
          subacceso: [
            { titulo: "AVL (v8.5)", link: "https://gt.disatelgps.com/" },
            { titulo: "Traffilog", link: "https://html5.traffilog.com/appv2/index.htm" },
            { titulo: "Satlock", link: "http://www.satlockonline.com/" }
          ]
        },
        {
          tipo: "1",
          titulo: "Panamá",
          link: "",
          subacceso: [
            { titulo: "AVL (v8.5)", link: "https://pa.disatelgps.com/" },
            { titulo: "Traffilog", link: "https://html5.traffilog.com/appv2/index.htm" },
            { titulo: "Satlock", link: "http://www.satlockonline.com/" }
          ]
        },
        {
          tipo: "1",
          titulo: "Bolivia",
          subacceso: [
            {titulo: "AVL (v8.5)", link: "https://bo.disatelgps.com/"}
          ]
        }
      ],
      drawer: false,
      group: null
    };
  },
  methods: {
    ShowDrawer() {
      this.drawer = !this.drawer;
    },
    SizeSubAcceso(value) {
      let size = value.length;
      return size;
    },
    onScroll (e) {
      this.offsetTop = e.target.scrollTop
    },
  }
};
</script>