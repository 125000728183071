const animateScrollObserver = new IntersectionObserver(
    (entries, animateScrollObserver) => {
        entries.forEach((entry) => {
            if(entry.isIntersecting){
                entry.target.classList.add('enter');
                animateScrollObserver.unobserve(entry.target);
            }
        });
    }
);

export default{
    bind(el){
        el.classList.add('before-enter');
        animateScrollObserver.observe(el);
    }
}