import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: () => import('../views/Nosotros.vue')
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: () => import('../views/Servicios.vue')
  },
  {
    path: '/solucion/:solucion',
    name: 'Solucion',
    component: () => import('../views/Solucion.vue')
  },
  {
    path: '/contactanos',
    name: 'Contactanos',
    component: () => import('../views/Contactanos.vue')
  },
  {
    path: '/noticia-marchamo-electronico',
    name: 'Marchamo Electrónico',
    component: () => import('../views/Noticia1.vue')
  },
  {
    path: '/send-message',
    name: 'SendMesagge',
    component: () => import('../views/SendMessage.vue')
  },
  {
    path: '/logical-commander',
    name: 'Logical Commander',
    component: () => import('../views/LogicalCommander.vue')
  },
  /*{
    path: '/blog/:post',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },*/
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition){
    return { x:0, y:0 }
  },
  routes
})


export default router
